@import url('https://fonts.googleapis.com/css?family=Bangers&display=swap');


body {
    padding: 0;
    margin: 0;
    background-color: black;
}


header {
    padding: 10px 0px;
    transition: all 300ms ease-in;
}

header .header_logo{
    flex-grow: 1;
}

header .header_logo_name {
    font-family: 'Bangers', cursive;
    font-size: 36px;
}

.sideList {
    font-family: 'Bangers', cursive;
    font-size: 22px;
}

/*====================
        CARROUSEL
======================*/


.carousel_wrapper {
    height: 800px;
    overflow: hidden;
    background-color: black;
}

.carousel_image {
    
    background-size: cover !important;
}

.carousel_image_three {
    background-size: contain;
}



/*====================
        SOUNDCLOUD
======================*/

.soundcloud_wrapper {
    width: 500px;
    height: auto;
    margin:0 auto;
    padding: 50px;

}


/*====================
        HIGHLIGHTS
======================*/


.center_wrapper h2 {
    font-family: 'Bangers', cursive;
    text-align: center;
    color: #F4F4F4;
    font-size: 52px;
    border-top: 1px solid #dddddd;
    width: 100%;
    padding: 30px 30px;
    background-color: #000000;
    margin-bottom: 0;


}



.highlight_description {
    font-family: 'Bangers', cursive;
    line-height: 30px;
    font-size: 18px;
    font-weight: 300;
    color: #F4F4F4;
    padding: 2em 2em 2em 2em;
}


/*====================
        FOOTER
======================*/



footer {
    padding: 20px 0px;
    text-align: center;
    font-size: 30px;
    font-family: 'Bangers', cursive;
    color:#ffffff;
    position: fixed;
}

footer .footer_copyright {
    font-size: 18px;
}

.bck_black {
    background: #3c3c3c;
}

.social_media {
    padding: 4px;
}

/*====================
        FOOTER
======================*/

.wrapper {
    height: 30em;
    border-bottom: 1px solid #dddddd;
}

.content {
    height: auto;
    text-align: center;
    overflow: auto;
}





